import { FC, useCallback, useEffect, useState } from 'react'
import { ApiRequestError, useGlobalState } from '../../state'
import { Badge, Button, Col, Container, Dropdown, ListGroup, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { parseNotifications } from '.'
import type { Notification, NotificationJsonWithMetadata, NotificationWithMetadata, UID } from '../../PloneApi'
import moment from 'moment'
import { BsCheck2, BsFunnel, BsTrash2 } from 'react-icons/bs'

const filterText = {
  all: 'Alle Benachrichtigungen',
  own: 'Eigene Benachrichtigungen',
  open: 'Offene Benachrichtigungen'
}

const NotificationManager: FC = () => {
  const { apiRequest, user } = useGlobalState()
  const [notifications, setNotifications] = useState<NotificationWithMetadata[]>([])
  const [filter, setFilter] = useState<keyof typeof filterText>('open')

  useEffect(() => {
    apiRequest<NotificationJsonWithMetadata[]>(`getNotifications${filter === 'own' ? '/own' : filter === 'open' ? '/open' : ''}`)
      .then(async json => await parseNotifications(json) as NotificationWithMetadata[])
      .then(setNotifications)
      .catch(err => console.error(err))
    }, [apiRequest, filter])

  const isNotificationClosedByEveryone = useCallback(
    (notification: NotificationWithMetadata) => notification.closedBy.length === notification.attendees.length,
    [])

  const hasUserClosedNotification = useCallback(
    (notification: NotificationWithMetadata, username: UID) => notification.closedBy.indexOf(username) >= 0,
    []
  )

  const closeNotification = useCallback(
    ({ id }: Notification) => apiRequest(`closeNotification/${id}`, 'POST')
      .catch((error: ApiRequestError) => {
        if (error.response.status === 409) {
          console.warn(error)
        } else {
          console.error(error)
        }
      }),
    [apiRequest]
  )

  const deleteNotification = useCallback(
    ({ id }: Notification) => apiRequest(`deleteNotification/${id}`, 'POST')
      .catch(async (error: ApiRequestError) => {
        if (error.response.status === 400 && /^Unable to delete notification with id \w+$/.test((await error.response.json() as { error: string }).error)) {
          console.warn(await error.response.json())
        } else {
          console.error(await error.response.json())
        }
      }),
    [apiRequest]
  )

  return (
    <Container fluid>
      <Row className='my-3'>
        <Col>
          <Dropdown>
            <Dropdown.Toggle><BsFunnel /> {filterText[filter]}</Dropdown.Toggle>
            <Dropdown.Menu>
              {Object.entries(filterText).map(([key, value]) => (
                <Dropdown.Item key={key} as='button' disabled={(key === 'all' && user?.isAdmin !== true) || (key === 'own' && user?.isTrainee === true)} onClick={() => setFilter(key as keyof typeof filterText)}>{value}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <ListGroup>
        {
          notifications.map(notification => (
            <ListGroup.Item key={notification.id} variant={user?.isTrainee === false && isNotificationClosedByEveryone(notification) ? 'success' : ''}>
              <Row>
                <Col md='4'>
                  <Row>
                    <Col>{notification.title}</Col>
                  </Row>
                  <Row>
                    <Col>Erstellt von: <strong>{notification.creator}</strong></Col>
                  </Row>
                </Col>
                <Col xs='3'>{notification.text}</Col>
                <Col xs='2'>
                  {user?.isTrainee === false ? notification.attendees.map(attendee => (
                    <Badge
                      key={attendee}
                      color='success'
                      bg={hasUserClosedNotification(notification, attendee) ? 'primary' : 'secondary'}
                      style={{ marginRight: '5px' }}
                    >
                      {attendee}
                    </Badge>
                  )) : ''}
                </Col>
                <Col xs='2'>
                  <OverlayTrigger placement='bottom' overlay={(<Tooltip id={`notification-manager-tooltip-${notification.id}`}>{moment(notification.created).calendar()}</Tooltip>) as any}>
                    <time dateTime={notification.created.toISOString()}>{moment(notification.created).fromNow()}</time>
                  </OverlayTrigger>
                </Col>
                <Col xs='1'>
                  <Button variant='white' disabled={user?.isTrainee === false && notification.attendees.includes(user?.username as UID) && notification.closedBy.includes(user?.username as UID)} onClick={() => closeNotification(notification)}><BsCheck2 /></Button>
                  <Button variant='white' disabled={user?.isAdmin !== true && notification.creator !== user?.username} onClick={() => deleteNotification(notification)}><BsTrash2 /></Button>
                </Col>
              </Row>
            </ListGroup.Item>
          ))
        }
      </ListGroup>
    </Container>
  )
}

export default NotificationManager
