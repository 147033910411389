import { Button, Form, Modal } from 'react-bootstrap'
import { FC, useState } from 'react'
import DayPicker from 'react-day-picker'
import '../Styling/DayPicker.css'
import { CreateRecordBookURL } from '../../API/URL'
import { useGlobalState } from '../../state'
import { RecordBook } from '../../PloneApi'
import { AiOutlineInfoCircle } from 'react-icons/ai'

const CreateRecordBookModal: FC<{ show: boolean, handleClose: () => void }> = ({ show, handleClose }) => {

  const { session, recordBooks, setRecordBooks } = useGlobalState()
  const [week, setWeek] = useState<number>()
  const [customName, setCustomName] = useState<string>('')

  const createRecordBook = () => {
    fetch(CreateRecordBookURL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${session}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'id': customName === '' ? `Mein Berichtsheft KW ${week}` : customName
      })
    })
      .then((r) => r.json() as Promise<RecordBook>)
      .then(newRecordBook => {
        setRecordBooks([...recordBooks, {
          ...newRecordBook,
          createdAt: new Date(newRecordBook.createdAt),
          modifiedAt: new Date(newRecordBook.modifiedAt)
        }])
      })
  }

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Berichtsheft anlegen</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Kalenderwoche auswählen</Form.Label>

            <div className={'d-flex justify-content-center shadow-lg'}
                 style={{ minHeight: '400px' }}>
              <DayPicker
                showWeekNumbers
                onWeekClick={(week) => setWeek(week)}
              />
            </div>
            <div className={'d-flex justify-content-center'}>
              <Form.Control type="text"
                            placeholder={'Auwahl: KW ' + ((week == null) ? 'nicht Ausgewählt' : week)}
                            readOnly/>
            </div>
            <Form.Text className="text-muted">
              <AiOutlineInfoCircle className="mx-2"/>Wähle, um eine Kalenderwoche
              auszuwählen, einen der Blau markierten Bereiche aus.
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>Eigenen Namen verwenden </Form.Label>
            <Form.Text className="text-muted">
              <AiOutlineInfoCircle className="mx-2"/>(nicht erforderlich)
            </Form.Text>
            <Form.Control type="text"
                          onChange={(e) => setCustomName(e.target.value)}/>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="warning" onClick={() => handleClose()}>
          Abbrechen
        </Button>
        <Button variant="primary"
                onClick={() => {
                  createRecordBook()
                  handleClose()
                }}>
          Erstellen
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CreateRecordBookModal