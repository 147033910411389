import { createContext, FC, useCallback, useContext, useState } from 'react'
import {RecordBook, User} from "./PloneApi";
import { ploneInstanceURL } from './API/URL'

export class ApiRequestError extends Error {
  constructor (public readonly response: Response) { super(`[${response.status}] ApiRequestError`) }
}

type GlobalStateValue =
    Pick<ReturnType<typeof useProvideGlobalState>,
        'recordBooks' | 'session' | 'user' > &
    Partial<Pick<ReturnType<typeof useProvideGlobalState>,
        'setRecordBooks' | 'setSession' | 'setUser' | 'apiRequest'>>

const globalStateContext = createContext<GlobalStateValue>({
    recordBooks: [],
    session: null,
    user: null,
})

export const ProvideGlobalState: FC<{ children: JSX.Element }> = ({children}) => {
    const state = useProvideGlobalState()
    return <globalStateContext.Provider value={state}>{children}</globalStateContext.Provider>
}

export const useGlobalState = () => useContext(globalStateContext) as ReturnType<typeof useProvideGlobalState>

export const useProvideGlobalState = () => {
    const [recordBooks, setRecordBooks] = useState<RecordBook[]>([])
    const [user, setUser] = useState<User | null>(null)
    const [session, setSession] = useState<string | null>(null)

    const apiRequest = useCallback<(<R = void, B = any> (path: string, method?: 'GET'|'POST', body?: B, headers?: Record<string, string>, authorized?: boolean) => Promise<R>)>(
      async function <R = void, B = any> (
      path: string,
      method: 'GET' | 'POST' = 'GET',
      body: B,
      headers?: Record<string, string>,
      authorized: boolean = true
    ): Promise<R> {
      const initHeaders: HeadersInit = {
        Accept: 'application/json'
      }

      if (authorized) initHeaders.Authorization = `Bearer ${session}`
      if (body != null) initHeaders['Content-Type'] = 'application/json; charset=utf-8'

      const init: RequestInit = {
        headers: {
          ...initHeaders,
          ...headers
        },
        method
      }

      if (body != null) init.body = JSON.stringify(body)

      return await fetch(`${ploneInstanceURL}${path}`, init)
        .then(async response => {
          if (!response.ok) throw new ApiRequestError(response)
          else return await response.json() as Promise<R>
        })
    }, [session])

    const isSessionExpired = useCallback(() => {
      if (session === null) return true
      return JSON.parse(atob(session.split('.')[1])).exp - Date.now() / 1e3 <= 0
    }, [session])


    return {
        recordBooks, setRecordBooks,
        session, setSession,
        user, setUser,
        apiRequest,
        isSessionExpired
    }
}
