import {hexToRGB, stateToColor} from "./Utility";
import {FC} from "react";
import {RecordBook} from "../../PloneApi";
import {BsZoomIn} from "react-icons/bs";



const RecordBookListItem: FC<{ recordBook: RecordBook, editRecordBook: (recordBook: RecordBook) => void }> = ({
                                                                                                                  recordBook,
                                                                                                                  editRecordBook
                                                                                                              }) => {

    const getWorkHours = (): number => {
        let workHours: number = 0
        for (let i = 0; i < recordBook.days.length; i++) {
            for (let j = 0; j < recordBook.days[i].activities.length; j++) {
                workHours = workHours + recordBook.days[i].activities[j].duration
            }
        }
        return workHours
    }


    return (
        <tr className={"recordBookListItem"}>
            <td style={{color: "black", fontWeight: "bold"}}>
                {
                    recordBook.id
                }
            </td>
            <td>
                {
                    recordBook.creator
                }
            </td>
            <td>
                {
                    getWorkHours()
                }
            </td>

            <td>
                <time dateTime={new Date(recordBook.createdAt.getFullYear(), recordBook.createdAt.getMonth(), recordBook.createdAt.getDate()+7).toISOString()}>
                    {
                        new Date(recordBook.createdAt.getFullYear(), recordBook.createdAt.getMonth(), recordBook.createdAt.getDate() + 7).toLocaleDateString()
                    }
                </time>
                {

                }
            </td>
            <td>
                <div style={
                    {
                        borderRadius: "30px",
                        color: stateToColor(recordBook.state),
                        background: hexToRGB(stateToColor(recordBook.state), 0.2),
                        textAlign: 'center'
                    }
                }>
                    {
                        recordBook.state
                    }
                </div>
            </td>
            <td style={{textAlign: "end"}}>
                <BsZoomIn onClick={() => editRecordBook(recordBook)} size={30} color={'black'}/>
            </td>
        </tr>
    )

}

export default RecordBookListItem;