import React from 'react';
import {useGlobalState} from "../../state";
import {FiLogOut} from "react-icons/fi";

const LogoutButton = () => {

    const {setSession, setRecordBooks, setUser} = useGlobalState()
    return (
        <div className={"LogoutButton"} onClick={() => {
            setSession(null)
            setUser(null)
            setRecordBooks([])
        }}>
            <FiLogOut size={30}/>
        </div>
    );
};

export default LogoutButton;