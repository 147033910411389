import React, {FC, useEffect, useState} from 'react';
import {recordBookFolderURL, recordBookUpdateURL} from "../../API/URL";
import {Button} from 'react-bootstrap';
import RecordBookListItem from "./RecordBookListItem";
import {useGlobalState} from "../../state";
import {RecordBook, StatesType} from "../../PloneApi";
import StatePicker from "./StatePicker";
import RecordBookModal from "./RecordBookModal";
import CreateRecordBookModal from "./CreateRecordBookModal";
import {BsPlus} from "react-icons/bs";

const RecordBookManager: FC = () => {

    const {session, recordBooks, setRecordBooks} = useGlobalState()
    const [showRecordBook, setShowRecordBook] = useState<boolean>(false)
    const [createRecordBook, setCreateRecordBook] = useState<boolean>(false)
    const [activeRecordBook, setActiverecordBook] = useState<RecordBook>({
        url: "",
        id: "",
        days: [
            {date: "", name: "Montag", activities: [{activity: '', duration: 0}]},
            {date: "", name: "Dienstag", activities: [{activity: '', duration: 0}]},
            {date: "", name: "Mittwoch", activities: [{activity: '', duration: 0}]},
            {date: "", name: "Donnerstag", activities: [{activity: '', duration: 0}]},
            {date: "", name: "Freitag", activities: [{activity: '', duration: 0}]},
            {date: "", name: "Samstag", activities: [{activity: '', duration: 0}]},
            {date: "", name: "Sonntag", activities: [{activity: '', duration: 0}]}
        ],
        comment: "",
        creator: "admin",
        state: "draft",
        createdAt: new Date(),
        modifiedAt: new Date()
    })
    const [selectedState, setSelectedState] = useState<StatesType>("all")
    const [displayedRecordBooks, setDisplayedRecordBooks] = useState<RecordBook[]>([])


    const handleChangeRecordBook = (newRecordBook: RecordBook) => {
        fetch(recordBookUpdateURL, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${session}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({"recordBook": newRecordBook})
        })
            .then(r => r.json() as Promise<RecordBook>)
            .then(updatedRecordBook => {
                setRecordBooks(recordBooks.map((recordBook) => {
                    if (recordBook.id === updatedRecordBook.id)
                        return {...updatedRecordBook, createdAt: new Date(updatedRecordBook.createdAt), modifiedAt: new Date(updatedRecordBook.modifiedAt)}
                    return {...recordBook, createdAt: new Date(recordBook.createdAt), modifiedAt: new Date(recordBook.modifiedAt)}
                }))
            })
    }

    useEffect(() => {
        fetch(recordBookFolderURL, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${session}`
            },
        })
            .then(r => r.json() as Promise<RecordBook[]>)
            .then((newRecordBooks) => {
                setRecordBooks(newRecordBooks.map((recordBook) => {
                    return {...recordBook, createdAt: new Date(recordBook.createdAt), modifiedAt: new Date(recordBook.modifiedAt)}
                }))
            })
    }, [session, setRecordBooks])

    useEffect(() => {
        if(selectedState !== "all")
            setDisplayedRecordBooks(recordBooks.filter(({state}) => state === selectedState))
        else
            setDisplayedRecordBooks(recordBooks)
    }, [recordBooks, selectedState])


    const getStats: () => Record<"all" | "draft" | "review" | "denied" | "approved", number> = () => {
        let stats: Record<StatesType, number> = {
            "all": 0,
            "draft": 0,
            "review": 0,
            "denied": 0,
            "approved": 0
        }
        for (let i = 0; i < recordBooks.length; i++) {
            stats[recordBooks[i].state as StatesType] = stats[recordBooks[i].state as StatesType] + 1
        }
        return stats
    }

    return (
        <div className={"RecordBookManager"}>
            <div className={"RecordBookManagerOptions"}>
                <Button onClick={() => setCreateRecordBook(true)} className={"RecordBookManagerCreateRecordBook"}>
                    Neues Berichtsheft<BsPlus size={'30'}/>
                </Button>
            </div>
            <div className={"RecordBookManagerStates"}>
                <h2>
                    Status
                </h2>
                <StatePicker state={selectedState} setState={(state: StatesType) => setSelectedState(state)}
                             stats={getStats()}/>
            </div>
            <table className={"RecordBookManagerRecordBooks"}>
                <thead className={"RecordBookManagerRecordBooksMeta"}>
                <tr>
                    <th>
                        Kalenderwoche
                    </th>
                    <th>
                        Von
                    </th>
                    <th>
                        Arbeitszeit
                    </th>
                    <th>
                        Abgabe
                    </th>
                    <th>
                        Status
                    </th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                {
                    displayedRecordBooks.map((recordBook, idx) =>
                        <RecordBookListItem key={idx}
                            recordBook={recordBook}
                            editRecordBook={(recordBook: RecordBook) => {
                                setActiverecordBook(recordBook)
                                setShowRecordBook(true)
                            }}
                        />)
                }
                </tbody>
            </table>
            <RecordBookModal recordBook={activeRecordBook} show={showRecordBook}
                             handleClose={() => setShowRecordBook(false)}
                             handleChange={(newRecordBook) => handleChangeRecordBook(newRecordBook)}/>
            <CreateRecordBookModal show={createRecordBook} handleClose={() => setCreateRecordBook(false)}/>
        </div>
    )
}

export default RecordBookManager;