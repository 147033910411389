import React, {FC, useState} from 'react'
import {Button, FormControl, InputGroup, Modal, ModalBody} from 'react-bootstrap'
import ModalHeader from "react-bootstrap/ModalHeader";
import {BsFillEyeFill, BsFillEyeSlashFill, BsKey, BsPersonCircle} from "react-icons/bs";

const LoginModal: FC<{ show: boolean, login: (username: string, pass: string) => Promise<void> }> = ({login,show}) => {

    const [username, setUsername] = useState<string>('')
    const [pass, setPin] = useState<string>('')
    const [passVisibility, setPassVisibility] = useState<boolean>(false)


    return (
        <Modal show={show}>
            <ModalHeader>
                <h1>
                    Azubi Portal
                </h1>
            </ModalHeader>
            <ModalBody>
                <InputGroup className="mb-3">
                    <InputGroup.Text>
                        <BsPersonCircle/>
                    </InputGroup.Text>
                    <FormControl onChange={(e) => setUsername(e.target.value)} type="text"
                                 placeholder="Max Mustermann"/>
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="PasswordInput">
                        <BsKey/>
                    </InputGroup.Text>
                    <FormControl onChange={(e) => setPin(e.target.value)} type={passVisibility ? "text" : "password"}
                                 aria-label="Password"
                                 placeholder="Passwort"/>
                    <Button variant="outline-dark" onClick={() => setPassVisibility(!passVisibility)}>
                        {passVisibility ? <BsFillEyeFill/> : <BsFillEyeSlashFill/>}
                    </Button>
                </InputGroup>
                <Button variant="primary" onClick={() => login(username, pass)}>
                    Einloggen
                </Button>
            </ModalBody>
        </Modal>
    )
}

export default LoginModal;
