import { FC } from 'react'
import { Notification as NotificationT, UID } from '../../PloneApi'
import { Button, OverlayTrigger, Toast, Tooltip } from 'react-bootstrap'
import ColorConvert, { LuminanceAlgorithm } from 'color-convert'
import moment from 'moment'
import { BsCheck2 } from 'react-icons/bs'

const Notification: FC<{ data: NotificationT, onClose: (id: UID) => void, markClosed: (id: UID) => void }> = ({ data: { created, color, id, text, title }, onClose, markClosed }) => {
  return (
    <Toast
      key={id}
      className={(new ColorConvert(color)).calculateLuminance(LuminanceAlgorithm.W3C_AERT) <= 0.5 ? 'text-white' : ''}
      style={{ backgroundColor: color }}
      onClose={() => onClose(id)}
    >
      <Toast.Header>
        <strong className='me-auto'>{title}</strong>
        <small>
          <OverlayTrigger placement='bottom' overlay={(<Tooltip id={`notification-tooltip-${id}`}>{moment(created).calendar()}</Tooltip>) as any}>
            <time dateTime={created.toISOString()}>{moment(created).fromNow()}</time>
          </OverlayTrigger>
        </small>
        <Button variant='link' className='mx-1 px-0 text-secondary' onClick={() => markClosed(id)}><BsCheck2 /></Button>
      </Toast.Header>
      <Toast.Body>
        {text}
      </Toast.Body>
    </Toast>
  )
}

export default Notification
