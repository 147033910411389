import { createElement as ce } from 'react'
export const ploneInstanceURL = '_self' in ce('div') ? "http://api.azubiportal.localhost/" : 'https://plone.azubi.dev.interaktiv.de/'

export const ploneLoginURL = ploneInstanceURL + "@login";
export const ploneLogoutUrl = ploneInstanceURL + "@logout";
export const recordBookFolderURL = ploneInstanceURL + "getAllRecordBooks";
export const recordBookUpdateURL = ploneInstanceURL + "updateRecordBook"
export const recordBookURL = "getRecordBook";
export const CreateRecordBookURL = ploneInstanceURL + "createRecordBook";
export const changeRecordBookStateURL = ploneInstanceURL + "changeRecordBookState";
export const widgetSettingsURL = ploneInstanceURL + "getMyWidgets";
export const availableWidgetsURL = ploneInstanceURL + "getAvailableWidgets";
export const setWidgetsURL = ploneInstanceURL + "setWidget";