import React, { FC, useEffect, useLayoutEffect, useState } from 'react'
import Logo from "./Components/header/Logo";
import Navigation from "./Components/header/Navigation";
import {useGlobalState} from "./state";
import LogoutButton from "./Components/header/LogoutButton";
import LoginManager from "./Components/authentication/LoginManager";
import RecordBookManager from "./Components/recordBooks/RecordBookManager";
import SendMessage from "./Components/header/SendMessage";
import Notifications from './Components/Notifications'
import 'moment/locale/de'
import moment from 'moment'

const App: FC = () => {
    const {session, user} = useGlobalState()
    const [current, setCurrent] = useState<JSX.Element>(<LoginManager/>)

    useLayoutEffect(() => void moment.locale('de'), [])

    useEffect(() => {
        if (session === null) {
            setCurrent(<LoginManager/>)
        } else {
            setCurrent(<RecordBookManager/>)
        }
    }, [session, user])


    return (
        <div className="App">
            <header>
                <div className={"leftHeader"}>
                    <Logo/>
                    <div className={'headline'}>
                        <span style={{fontWeight: "bold"}}>
                            Azubi
                        </span>
                        <span>
                            portal
                        </span>
                    </div>
                </div>
                <Navigation setCurrent={(current: JSX.Element) => setCurrent(current)}/>
                <div className={'rightHeader'}>
                    <SendMessage/>
                    <LogoutButton/>
                </div>
            </header>
            <main>
                <Notifications />
                {
                    current
                }
            </main>
            <footer>

            </footer>
        </div>
    )
}

export default App
