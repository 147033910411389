import React, {FC, useState} from "react"
import RecordBookManager from "../recordBooks/RecordBookManager";
import NotificationManager from '../Notifications/Manager'

const Navigation: FC<{ setCurrent: (current: JSX.Element) => void }> = ({setCurrent}) => {


    const [currentName, setCurrentName] = useState<string>('rb')

    return (
        <div className={'NavigationContainer'}>
            <div className={"NavigationElement"} onClick={() => {
                setCurrent(<RecordBookManager/>)
                setCurrentName("db")
            }} data-active={currentName === "db"}>
                Dashboard
            </div>
            <div className={"NavigationElement"} onClick={() => {
                setCurrent(<RecordBookManager/>)
                setCurrentName("rb")
            }} data-active={currentName === "rb"}>
                Berichtshefte
            </div>
            <div className={"NavigationElement"} onClick={() => {
              setCurrent(<NotificationManager/>)
              setCurrentName("bn")
            }}
            data-active={currentName === "bn"}
            >
                Benachrichtigungen
            </div>
        </div>
    )
}

export default Navigation;
