import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { IoMdPaperPlane } from 'react-icons/io'
import CreateNotificationModal from '../Notifications/CreateModal'

const SendMessage = () => {

  const [show, setShow] = useState<boolean>(false)

  return (
    <>
      <Button variant={'dark'} onClick={() => setShow(true)}>
        Send Message <IoMdPaperPlane size={25}/>
      </Button>
      <CreateNotificationModal show={show} onHide={() => setShow(false)} />
    </>
  )
}

export default SendMessage