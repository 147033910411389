import { FC, useCallback, useEffect, useState } from 'react'
import { useGlobalState } from '../../state'
import type { NotificationAddData, NotificationWithMetadata } from '../../PloneApi'
import type { ModalProps } from 'react-bootstrap'
import { Badge, Button, Form, Modal } from 'react-bootstrap'
import { CirclePicker } from 'react-color'
import ColorConvert, { LuminanceAlgorithm } from 'color-convert'

export const notificationColors = [
  '#f44336', '#e91e63', '#9c27b0',
  '#673ab7', '#3f51b5', '#2196f3',
  '#03a9f4', '#00bcd4', '#009688',
  '#4caf50', '#8bc34a', '#cddc39',
  '#ffeb3b', '#ffc107', '#ff9800',
  '#ff5722', '#795548', '#607d8b'
]

const CreateModal: FC<Required<Pick<ModalProps, 'show'|'onHide'>>> = ({ onHide, show }) => {
  const { apiRequest } = useGlobalState()
  const [allUsernames, setAllUsernames] = useState<string[]>([])
  const [title, setTitle] = useState<NotificationAddData['title']>('')
  const [text, setText] = useState<NotificationAddData['text']>('')
  const [color, setColor] = useState<NotificationAddData['color']>('#fff')
  const [attendees, setAttendees] = useState<NotificationAddData['attendees']>([])
  
  useEffect(
    () => void apiRequest<string[]>('getAllUser')
      .then(setAllUsernames)
    , [apiRequest]
  )
  
  const userIsAttendeeOfNotification = useCallback((username: string) => attendees.indexOf(username) >= 0, [attendees])
  
  const toggleUserAttendeeState = useCallback((username: string) => setAttendees(prev => {
    const newArray = [...prev]
    
    if (newArray.indexOf(username) >= 0) {
      newArray.splice(prev.indexOf(username), 1)
    } else {
      newArray.push(username)
    }
    
    return newArray
  }), [])

  const reset = useCallback(() => {
    setTitle('')
    setText('')
    setColor('#fff')
    setAttendees([])
  }, [])
  
  return (
    <Modal show={show} onHide={onHide} backdrop='static'>
      <Modal.Header closeButton style={{ backgroundColor: color }} className={(new ColorConvert(color).calculateLuminance(LuminanceAlgorithm.W3C_AERT) <= 0.5 ? 'text-white' : '')}>
        <Modal.Title>Benachrichtigung anlegen</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className='mb-3'>
            <Form.Label>Titel:</Form.Label>
            <Form.Control
              type='text'
              value={title}
              onChange={({target: { value }}) => setTitle(value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Nachricht:</Form.Label>
            <Form.Control
              type='text'
              value={text}
              onChange={({ target: { value } }) => setText(value)}
            />
          </Form.Group>
          <div className='my-1'>
            <Form.Label>Empfänger:</Form.Label>
            <div>
              <Badge
                bg={allUsernames.length === attendees.length ? 'success' : 'dark'}
                onClick={() => setAttendees(prev => prev.length === allUsernames.length ? [] : allUsernames)}
                style={{ marginRight: '5px', cursor: 'pointer', fontSize: '16px'}}
              >
                Alle
              </Badge>
              {
                allUsernames.map(user => (
                  <Badge
                    key={user}
                    bg={userIsAttendeeOfNotification(user) ? 'success' : 'secondary'}
                    onClick={() => toggleUserAttendeeState(user)}
                    style={{ marginRight: '5px', cursor: 'pointer', fontSize: '16px' }}
                  >
                    {user}
                  </Badge>
                ))
              }
            </div>
          </div>
          <div className='my-1'>
            <Form.Label>Farbe:</Form.Label>
            <CirclePicker
              onChange={({ hex }: { hex: string }) => setColor(hex)}
              width='100%'
              colors={notificationColors}
            />
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: color }}>
        <Button variant='dark' onClick={() => { reset(); onHide() }}>Abbrechen</Button>
        <Button variant='primary' onClick={
          () => apiRequest<NotificationWithMetadata, NotificationAddData>(
            'addNotification',
            'POST',
            {
              title,
              text,
              color,
              attendees
            }
          )
            .catch(err => console.error(err))
            .finally(() => { reset(); onHide() })
        }>
          Erstellen
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CreateModal
