import { FC, useCallback, useEffect, useRef, useState } from 'react'
import {
  Notification as NotificationT,
  NotificationJsonData,
  UID
} from '../../PloneApi'
import { ApiRequestError, useGlobalState } from '../../state'
import { ToastContainer } from 'react-bootstrap'
import NotificationElement from './Notification'

export const parseNotifications = async (json: NotificationJsonData[]): Promise<NotificationT[]> => await new Promise(resolve => {
  const parsed: NotificationT[] = []
  for (let i = 0, notification = json[i]; i < json.length; notification = json[++i]) {
    parsed.push({
      ...notification,
      created: new Date(notification.created)
    })
  }
  resolve(parsed)
})

const Notifications: FC = () => {
  const { apiRequest, session } = useGlobalState()
  const [notifications, setNotifications] = useState<NotificationT[]>([])
  const lastNotifications = useRef<UID[]>([])
  const [useNativeNotifications, setUseNativeNotifications] = useState(false)

  useEffect(() => {
    // Check or request to use native Web Notification API
    if ('Notification' in window) {
      if (Notification.permission === 'granted') {
        setUseNativeNotifications(true)
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then(permission => {
          if (permission === 'granted') setUseNativeNotifications(true)
        })
      }
    }

    if (session === null) return

    // Get open Notifications for current user
    apiRequest<NotificationJsonData[]>('/getNotifications/open')
      .then(parseNotifications)
      .then(setNotifications)
      .catch(err => {
        if (err instanceof ApiRequestError) {
          setNotifications(prev => [...prev, {
            id: `api_error_${Date.now()}`,
            title: 'Api Error',
            text: err.message,
            created: new Date(),
            color: 'hsl(30deg 40% 60%)'
          } as NotificationT])
        }
        console.error(err)
      })
      .finally(() => {
        setInterval(() => {}, 1e3 * 60 * 5)
      })
  }, [apiRequest, session])

  useEffect(() => {
    const delta = notifications.filter(({ id }) => lastNotifications.current.indexOf(id) === -1)
    if (useNativeNotifications) {
      for (const { id, title, text, created, color } of delta) {
        new Notification(title, {
          dir: 'ltr',
          lang: 'de-DE',
          body: text,
          tag: id,
          icon: `data:image/svg+xml;base64,${btoa('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><rect x="0" y="0" width="100" height="100" fill="color" /></svg>')}`,
          data: {
            created,
            color
          }
        })
      }
    }
    lastNotifications.current = notifications.map(({ id }) => id)
  }, [notifications, useNativeNotifications])

  const markNotificationAsClosed = useCallback(
    (id: UID) => apiRequest(`closeNotification/${id}`, 'POST')
      .then(() => setNotifications(prev => {
        const idx = notifications.findIndex(notification => notification.id === id)
        const newArray = [...prev]
        if (idx === -1) return newArray
        newArray.splice(idx, 1)
        return newArray
      }))
      .catch(async (err: ApiRequestError) => {
          const data = await err.response.json().catch(() => {}) as { error: string }

          if (data?.error != null) {
            setNotifications(prev => [...prev, {
              id: `__error_close_${id}_${Date.now()}`,
              title: 'Api Error',
              text: err.message,
              created: new Date(),
              color: 'hsl(30deg 40% 60%)'
            } as NotificationT])
          }
        console.error(err)
      })
    , [apiRequest, notifications])

  const hideNotification = useCallback((id: UID) => setNotifications(prev => {
    const newArray = [...prev]
    const idx = newArray.findIndex(notification => notification.id === id)
    if (idx === -1) return newArray
    newArray.splice(idx, 1)
    return newArray
  }), [])

  return (
    <ToastContainer position='top-end' className='position-fixed mt-4 me-4' style={{ zIndex: 9 }}>
      {notifications.reverse().map((notification) => (
        <NotificationElement data={notification} onClose={hideNotification} markClosed={markNotificationAsClosed} />
      ))}
    </ToastContainer>
  )
}

export default Notifications
