import React, {FC} from 'react';
import {StatesType} from "../../PloneApi";
import {stateToColor} from "./Utility";
import {BsBinoculars, BsDashCircle, BsFileEarmarkCheck, BsFileEarmarkMedical, BsInboxes} from "react-icons/bs";

const StatePicker: FC<{state:StatesType, setState: (state: StatesType) => void , stats: Record<StatesType, number>}> = ({state, setState, stats}) => {


    return (
        <div className={"RecordBookStatePicker"}>
            <div
                data-active={state === "all"}
                className={"RecordBookStatePickerElement"}
                onClick={() => setState("all")}
                style={{borderLeft: `2px solid ${stateToColor("all")}`}}>
                <BsInboxes/>
                <span>
                    Alle berichtshefte
                </span>
            </div>
            <div
                data-active={state === "draft"}
                className={"RecordBookStatePickerElement"}
                onClick={() => setState("draft")}
                style={{borderLeft: `2px solid ${stateToColor("draft")}`}}>
                <BsFileEarmarkMedical/>
                <span>
                    Entwurf
                </span>
                <div className={"RecordBookStatePickerElementStat"}>
                    {
                        stats["draft"]
                    }
                </div>
            </div>
            <div
                data-active={state === "review"}
                className={"RecordBookStatePickerElement"}
                onClick={() => setState("review")}
                style={{borderLeft: `2px solid ${stateToColor("review")}`}}>
                <BsBinoculars/>
                <span>
                    Wird Geprüft
                </span>
                <div className={"RecordBookStatePickerElementStat"}>
                    {
                        stats["review"]
                    }
                </div>
            </div>
            <div
                data-active={state === "denied"}
                className={"RecordBookStatePickerElement"}
                onClick={() => setState("denied")}
                style={{borderLeft: `2px solid ${stateToColor("denied")}`}}>
                <BsDashCircle/>
                <span>
                    Beanstandungen
                </span>
                <div className={"RecordBookStatePickerElementStat"}>
                    {
                        stats["denied"]
                    }
                </div>
            </div>
            <div
                data-active={state === "approved"}
                className={"RecordBookStatePickerElement"}
                onClick={() => setState("approved")}
                style={{borderLeft: `2px solid ${stateToColor("approved")}`}}>
                <BsFileEarmarkCheck/>
                <span>
                    Abgenommen
                </span>
                <div className={"RecordBookStatePickerElementStat"}>
                    {
                        stats["approved"]
                    }
                </div>
            </div>
        </div>
    );
};

export default StatePicker;