import { Button, Modal } from 'react-bootstrap'
import { FC, useEffect, useState } from 'react'
import { Activity, RecordBook } from '../../PloneApi'

const RecordBookModal: FC<{ recordBook: RecordBook, show: boolean, handleClose: () => void, handleChange: (newRecordBook: RecordBook) => void }> = ({
  recordBook,
  show,
  handleClose,
  handleChange
}) => {
  const [editableCopy, setEditableCopy] = useState<RecordBook>({
    url: '',
    id: '',
    days: [
      { date: '', name: 'Montag', activities: [{ activity: '', duration: 0 }] },
      { date: '', name: 'Dienstag', activities: [{ activity: '', duration: 0 }] },
      { date: '', name: 'Mittwoch', activities: [{ activity: '', duration: 0 }] },
      { date: '', name: 'Donnerstag', activities: [{ activity: '', duration: 0 }] },
      { date: '', name: 'Freitag', activities: [{ activity: '', duration: 0 }] },
      { date: '', name: 'Samstag', activities: [{ activity: '', duration: 0 }] },
      { date: '', name: 'Sonntag', activities: [{ activity: '', duration: 0 }] }
    ],
    comment: '',
    creator: 'admin',
    state: 'draft',
    createdAt: new Date(),
    modifiedAt: new Date()
  })
  const [selectedActivities, setSelectedActivities] = useState<number[]>([])
  const [newActivity, setNewActivity] = useState<Activity>({ activity: '', duration: 0 })
  const [currentDay, setCurrentDay] = useState<string>(new Date().toLocaleDateString('de-DE', { weekday: 'long' }))

  useEffect(() => {
    setEditableCopy(recordBook)
  }, [recordBook])

  useEffect(() => {
    setSelectedActivities([])
  }, [currentDay])

  return (
    <Modal size="lg" show={show} onHide={handleClose} backdrop="static" fullscreen="md-down" centered>
      <Modal.Header closeButton>
        <Modal.Title>{recordBook.id}</Modal.Title>
      </Modal.Header>
      <Modal.Header>
        {
          editableCopy.days.map((day, idx) => {
            return <Button key={idx} active={currentDay === day.name}
                           onClick={() => setCurrentDay(day.name)}>
              {
                day.name
              }
            </Button>
          })
        }
      </Modal.Header>
      <Modal.Body>
        <div className={'dayContainerOptions'}>
          <Button variant={'danger'}
                  size={'sm'}
                  disabled={selectedActivities.length <= 0}
                  onClick={() => {
                    setEditableCopy({
                      ...editableCopy, days: editableCopy.days.map((day) => {
                        if (day.name === currentDay)
                          return {
                            ...day,
                            activities: day.activities.filter((activity, idx) => !selectedActivities.includes(idx))
                          }
                        return day
                      })
                    })
                    setSelectedActivities([])
                  }}>
            Löschen
          </Button>
          <Button variant={'secondary'} size={'sm'} disabled>
            Kopieren
          </Button>
          <Button variant={'secondary'} size={'sm'} disabled>
            Auschneiden
          </Button>
        </div>
        <div className={'dayContainer'}>
          {
            editableCopy.days.filter((day) => (day.name === currentDay))[0].activities.map((activity, activityId) =>
              <div className={'entry'}>
                <input
                  type={'checkbox'}
                  checked={selectedActivities.includes(activityId)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedActivities([...selectedActivities, activityId])
                    } else {
                      selectedActivities.splice(selectedActivities.indexOf(activityId), 1)
                      setSelectedActivities([...selectedActivities])
                    }
                  }}
                />
                <input
                  type="text"
                  value={activity.activity}
                  onChange={(e) => {
                    setEditableCopy({
                      ...editableCopy,
                      days: editableCopy.days.map((day) => {
                        if (day.name === currentDay)
                          return {
                            ...day, activities: day.activities.map((oldActivity, idx) => {
                                if (activityId === idx)
                                  return { ...oldActivity, activity: e.target.value }
                                return oldActivity
                              }
                            )
                          }
                        return day
                      })
                    })
                  }}/>
                <input
                  type="number"
                  value={activity.duration}
                  onChange={(e) => {
                    setEditableCopy({
                      ...editableCopy,
                      days: editableCopy.days.map((day) => {
                        if (day.name === currentDay)
                          return {
                            ...day, activities: day.activities.map((oldActivity, idx) => {
                                if (activityId === idx)
                                  return {
                                    ...oldActivity,
                                    duration: parseInt(e.target.value)
                                  }
                                return oldActivity
                              }
                            )
                          }
                        return day
                      })
                    })
                  }}/>
              </div>
            )}
        </div>
        <div className={'newEntry'}>
          <input
            type="text"
            placeholder="Neuer Eintrag"
            value={newActivity.activity}
            onChange={(e) => setNewActivity({ ...newActivity, activity: e.target.value })}
          />
          <input
            type="number"
            placeholder="0"
            value={newActivity.duration}
            onChange={(e) => setNewActivity({ ...newActivity, duration: parseInt(e.target.value) })}
          />
          <Button onClick={() => {
            setEditableCopy({
              ...editableCopy, days: editableCopy.days.map((day) => {
                if (day.name === currentDay) {
                  return {
                    ...day,
                    activities: [...day.activities, newActivity]
                  }
                }
                return day
              })
            })
          }}>
            Hinzufügen
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant={'dark'} onClick={() => handleChange(editableCopy)}>
          Löschen
        </Button>
        <Button variant={'dark'} onClick={() => handleChange(editableCopy)}>
          Speichern
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default RecordBookModal

