import { FC, useCallback } from 'react'
import LoginModal from './LoginModal'
import { useGlobalState } from '../../state'
import { User } from '../../PloneApi'

const LoginManager: FC = () => {
  const { apiRequest, setSession, session, setUser } = useGlobalState()

  const login = useCallback(
    (username: string, pass: string) => apiRequest<{ token: string }, { login: string, password: string }>('@login', 'POST', { login: username, password: pass })
      .then(({ token }) => apiRequest<User>('getUserInfos', 'GET', null, { Authorization: `Bearer ${token}` }, false)
        .then(user => {
          setSession(token)
          setUser(user)
        })
      )
    , [apiRequest, setSession, setUser]
  )

  return (
    <LoginModal show={(session == null)} login={login}/>
  )
}

export default LoginManager


