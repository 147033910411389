export const stateToColor = (state: string) => {
  let color;
  switch(state){
    case "approved":
      color = "#596f4f";
      break;
    case "denied":
      color = "#fe6d5f";
      break;
    case "draft":
      color = "#efcf41";
      break;
    case "review":
      color = "#b4ccdb";
      break;
    default:
      color = "#2d2a2a";
      break;
  }
  return color;
}

export function hexToRGB(hex: string, alpha: number) {
  let r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}